.login_container {
  @apply flex flex-col justify-center items-center h-full w-full gap-14 bg-[#F0F4F9];
}

.login_title {
  @apply text-h5 text-font-textcolor1 font-semibold sm:mb-4;
  letter-spacing: 0px;

  // @media screen and (min-width: 1536px) {
  //   @apply text-2xl;
  // }

  // @media screen and (min-width: 1800px) {
  //   @apply text-h6 mb-8;
  // }
}

.login_form_separates {
  @apply flex flex-col justify-center items-center;
}

.login_card {
  @apply flex justify-between items-start tracking-tighter bg-white p-10 rounded-[28px] gap-3 lg:gap-20;

  // @media screen and (min-width: 1400px) {
  //   @apply w-[800px];
  // }

  // @media screen and (min-width: 1800px) {
  //   @apply w-[900px];
  // }
}

.login_box {
  @apply grid grid-cols-1 justify-items-end items-center w-full h-full;
}

.login_section {
  @apply relative h-screen bg-contain bg-top bg-no-repeat;
}

.login_section_text {
  @apply text-font-textcolor3 text-body1 font-normal text-left w-full;
  letter-spacing: 0.02em;
}

.login_details_separates {
  @apply w-full;

  // @media screen and (min-width: 1800px) {
  //   @apply gap-[22px];
  // }
}

.login_separates {
  @apply w-full flex flex-col justify-center items-center  gap-3;
}

.login_pw {
  @apply text-primary-main w-full flex flex-col items-end;
  letter-spacing: 0.02em;
}

.create_text {
  @apply text-body1 2xl:text-base mt-1;
  letter-spacing: 0.02em;
}

.login_page_title {
  @apply text-h6 2xl:text-h4 font-bold mt-0 w-full flex justify-center;
  letter-spacing: -0.5px;

  @media screen and (min-width: 1800px) {
    @apply text-h3;
  }
}

.otp_input_fields {
  @apply w-full flex justify-center items-center;
  .otp_input {
    @apply p-2 h-10 w-10 text-center outline-none text-base rounded-[4px];

    &:focus,
    &:hover {
      @apply border-primary-main outline-none ring-transparent;
    }
  }

  .otp_input::-webkit-outer-spin-button,
  .otp_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
